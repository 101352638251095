import React, { useEffect, useState } from "react";
import { Routes, Route } from 'react-router-dom';
import { ExternalForm } from './components/form/externalForm';
import { PreviewForm } from './components/form/previewForm';
import { ExternalTestForm } from './components/form/externalTestForm';
import { HomePage } from './components/homePage';
import { Loading } from './components/_helpers/loading';
import { AlertDialog } from './components/_helpers/alertDialog';
import { utils } from './_helpers/utils';

const App = () => {

    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState();

    useEffect(() => {
        utils.loading.subject.subscribe((x) => { setLoading(x) });
    }, [loading]);

    useEffect(() => {
        utils.alert.subject.subscribe((x) => { setAlert(x) });
    }, [alert]);

    return (
        <div>
            <Routes>

                <Route exact path='/:shortLink' element={<ExternalForm />} />

                <Route exact path='/tablet/:shortLink' element={<ExternalForm hideQrCode={true} />} />

                <Route exact path='/r/:shortLink' element={<ExternalForm readonlyForm={true} />} />

                <Route exact path='/s/:shortLink' element={<ExternalForm hideQrCode={true} hidePrint={true} hideDownload={true} />} />

                <Route exact path='/mm/:shortLink' element={<ExternalForm hideQrCode={true} hidePrint={true} hideDownload={true} />} />

                <Route exact path='/previewform/:shortLink' element={<PreviewForm />} />

                <Route exact path='/testForm/:language/:shortLink' element={<ExternalTestForm />} />

                <Route exact path='/tablet/testForm/:language/:shortLink' element={<ExternalTestForm hideQrCode={true} />} />

                <Route path='*' element={<HomePage />} />

            </Routes>

            {loading && <Loading />}
            {alert && <AlertDialog title={alert.title} text={alert.text} onClose={() => utils.alert.set()} />}
        </div>
    );
}

export default App;
