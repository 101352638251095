import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { externalService } from '../../_services/external.service';
import { utils } from '../../_helpers/utils';
import $ from "expose-loader?exposes=$,jQuery!jquery";
import "@progress/kendo-ui";
import "../../kendo-ui-license";
import '../../kendo/cultures/kendo.culture.ro-RO.js';
import '../../kendo/cultures/kendo.culture.fr-FR.js';
import { errorMessages } from "../../_helpers/errorMessages.js";
import { Signature } from "@progress/kendo-react-inputs";
import { Dialog } from "@progress/kendo-react-dialogs";
import { CustomIframe } from './customIframe';
import { ExportForm } from './exportForm';
import { getIframeBody, getFormField, initFormFields, setElementValue, initDropdownList, setDropdownDataSource, runOnErrorImage, adjustButtonsSize, setDateAndTime, initCustomDataSource } from "../../_helpers/iFrame";
import { SpeechRecognitionHandler } from './speechRecognition.js';      


export const ExternalForm = (props) => {
    const params = useParams();
    //const language = params.language ? params.language : "RO";
    const shortLink = params.shortLink;
    const [formResponseId, setFormResponseId] = React.useState(null);
     
    const [errorContent, setErrorContent] = React.useState(false);
    const [language, setLanguage] = React.useState("RO");
    const [htmlContent, setHtmlContent] = React.useState("");
    const [formFields, setFormFields] = React.useState([]);
    const [dataItems, setDataItems] = React.useState({
        dropdowns: [],
        custom: [],
        initialized: false
    });

    const [itemSignatureDialog, setItemSignatureDialog] = React.useState();
    const [formChanged, setFormChanged] = React.useState(false);
    const [initDataLoaded, setInitDataLoaded] = React.useState(true);
    const [awaitableDropdowns, setAwaitableDropdowns] = React.useState([]);
    const [speechRecognitionElement, setSpeechRecognitionElement] = React.useState(null);
    const [isFinalized, setIsFinalized] = React.useState(false);
    const [date, setDate] = React.useState(new Date());

    const imgTransparent = '/images/transparent.png';
    const imagesNotFound = useRef([]);
    const hideQrCode = props && props.hideQrCode;
    const readonlyForm = props && props.readonlyForm;
    const hideSave = props && props.hideSave;
    const hideDownload = props && props.hideDownload;
    const hidePrint = props && props.hidePrint;

    $('#formHtmlContent').on('load', function () {
        setDateAndTime(date);
    });

    const getDataSource = (item, parent, initial) => {
        externalService.getDataSource(
            {
                shortLink: shortLink,
                formFieldId: item.formFieldId,
                parent: parent,
                language: language
            })
            .then(response => {
                if (response) {
                    setDropdownDataSource(item.name, item.type, response)
                    if (initial) {
                        let _elem = getIframeBody().find('input[name="' + item.name + '"]');
                        let _field = getFormField(_elem[0], thisComponent);
                        if (_field?.value) {
                            setElementValue(_elem[0], _field.value);
                        }
                    }
                }
            });
    }

    const getDataSourceSingle = (parentValue, fieldAutoComplete, currentElement) => {
        externalService.getDataSource(
            {
                shortLink: shortLink,
                formFieldId: fieldAutoComplete.formFieldId,
                parent: parentValue,
                language: language
            })
            .then(response => {
                if (response && response[0]) {
                    setElementValue(currentElement, response[0].display);
                }
            });
    };

    const onSignatureClick = (event) => {
        if (isFinalized || readonlyForm) {
            return;
        }
        setItemSignatureDialog(event.target);
    }

    const OnReinitSqlDataClick = (event) => {
        if (event?.currentTarget) {
            var area = event.currentTarget.getAttribute("reinit-sqldata");
            if (area) {
                var elements = getIframeBody()
                    .find(`[reinit-sqldata="${area}"][name], [reinit-sqldata="${area}"] [name]`);

                let htmlTagNames = [];

                for (var i = 0; i < elements.length; i++) {
                    htmlTagNames.push(elements[i].getAttribute('name'));
                }

                externalService.reInitFromSqlData(
                    {
                        htmlTagNames: htmlTagNames,
                        shortLink: shortLink,
                        language: language
                    })
                    .then(response => {
                        if (response) {
                            for (var i = 0; i < elements.length; i++) {
                                let _name = elements[i].getAttribute('name');
                                let itemFound = response.find(x => x.htmlTagName == _name);
                                if (itemFound) {
                                    setElementValue(elements[i], itemFound.value);
                                }
                            }
                        }
                    });
            }
        }
    }

    const reInitFromSqlData = (elements, htmlTagNames) => {
        externalService.reInitFromSqlData(
            {
                htmlTagNames: htmlTagNames,
                shortLink: shortLink,
                language: language
            })
            .then(response => {
                if (response) {
                    for (var i = 0; i < elements.length; i++) {
                        let _name = elements[i].getAttribute('name');
                        let itemFound = response.find(x => x.htmlTagName == _name);
                        if (itemFound) {
                            setElementValue(elements[i], itemFound.value);
                        }
                    }
                }
            });
    }

    const getInitialDataSources = (dropdownItems, customItems) => {
        setInitDataLoaded(false);
        externalService.getInitialDataSources(
            {
                shortLink: shortLink,
                language: language,
                formFieldsList: dropdownItems.map(x => {
                    return {
                        formFieldId: x.item.formFieldId,
                        parent: x.parent,
                        type: x.type
                    };

                }).concat(customItems.map(x => {
                    return {
                        formFieldId: x.formFieldId,
                        parent: null,
                        type: x.type
                    }
                }))
            })
            .then(response => {
                if (response) {
                    for (var i = 0; i < dropdownItems.length; i++) {
                        let item = dropdownItems[i].item;
                        let parent = dropdownItems[i].parent;
                        let ds = response.find(x => x.formFieldsList.some(y => y.parent == parent && y.formFieldId == item.formFieldId));

                        if (ds) {
                            setDropdownDataSource(item.name, item.type, ds.dataSource);

                            let _elem = getIframeBody().find('input[name="' + item.name + '"]');
                            let _field = getFormField(_elem[0], thisComponent);
                            if (_field?.value) {
                                setAwaitableDropdowns([...awaitableDropdowns, item.name]);

                                setElementValue(_elem[0], _field.value);
                            }
                        }
                    }

                    for (const item of customItems) {

                        let ds = response.find(x => x.formFieldsList.some(y => y.formFieldId == item.formFieldId));

                        if (ds) {
                            let _elem = getIframeBody().find('div[name="' + item.name + '"]');

                            initCustomDataSource(_elem, ds.customDataSource);
                        }
                    }

                    setInitDataLoaded(true);
                }
            });
    }

    const thisComponent = {
        preview: false,
        isFinalized: isFinalized || readonlyForm,
        language: language,
        dropdownList: dataItems.dropdowns,
        formFields: formFields,
        formResponseId: formResponseId,
        setFormChanged: setFormChanged,
        setDropdownList: (dropdownList) => setDataItems({ ...dataItems, dropdowns: dropdownList }),
        setDataItems: setDataItems,
        setSpeechRecognitionElement: setSpeechRecognitionElement,
        reInitFromSqlData: reInitFromSqlData,
        getInitialDataSources: getInitialDataSources,
        getDataSource: getDataSource,
        getDataSourceSingle: getDataSourceSingle,
        onSignatureClick: onSignatureClick,
        OnReinitSqlDataClick: OnReinitSqlDataClick
    };

    // #region init
    useEffect(() => {
        if (!shortLink) {
            utils.alert.set({
                title: errorMessages.errorName[language],
                text: errorMessages.invalidUrl[language]
            });

            // eroare 
            setHtmlContent(errorMessages.generalError[language]);
            setErrorContent(true);

            return;
        }

        externalService.initForm(shortLink)
            .then(response => {
                if (!response) {
                    // eroare 
                    setHtmlContent(errorMessages.generalError[language]);
                    setErrorContent(true);

                    return;
                }

                if (!readonlyForm && response?.hasExpired) {
                    // eroare 
                    setHtmlContent(errorMessages.formExpired[language]);
                    setErrorContent(true);

                    return;
                }

                let _formResponseId = formResponseId;

                if (response) {

                    setLanguage(response.language);

                    setHtmlContent(response.htmlContent);
                    setIsFinalized(response.isFinalized);

                    if (response.formResponseId) {
                        _formResponseId = response.formResponseId;
                        setFormResponseId(_formResponseId);
                    }

                }

                externalService.formFields(
                    {
                        formResponseId: _formResponseId,
                        shortLink: shortLink,
                        language: response.Language
                    })
                    .then(response => {
                        if (response) {
                            setFormFields(response);
                        }
                        else {
                            // pe eroare nu mai afisam html-ul
                            setHtmlContent(errorMessages.generalError[language]);
                            setErrorContent(true);
                        }
                    });
            });

       

    }, []);

    const handleOnInit = () => {
        runOnErrorImage(imagesNotFound);
        initFormFields(thisComponent);
    }

    useEffect(() => {
        adjustButtonsSize();
    }, [formResponseId]);

    useEffect(() => {
        // setam culture in functie de language
        setCurrentCulture();

    }, [language]);

    useEffect(() => {

        if (dataItems.initialized === true) {

            let initializedDropdowns = [];

            if (dataItems.dropdowns.length > 0) {
                initializedDropdowns = initDropdownList(thisComponent);
            }

            getInitialDataSources(initializedDropdowns, dataItems.custom);

        }

    }, [dataItems]);

    const setCurrentCulture = () => {
        let _lang = '';
        switch (language) {
            case "EN":
                _lang = 'en-US';
                break;
            case 'FR':
                _lang = 'fr-FR';
                break;
            default:
                _lang = 'ro-RO';
        }

        if (kendo) {
            kendo.culture(_lang);
        }
    }

    const onSubmitSignature = (imgBase64) => {
        if (itemSignatureDialog) {
            if (!imgBase64) {
                itemSignatureDialog.src = imgTransparent;
                itemSignatureDialog.classList.remove('signed');
            }
            else {
                itemSignatureDialog.src = imgBase64;
                itemSignatureDialog.classList.add('signed');
                itemSignatureDialog.classList.remove('pulse');
            }
        }

        setItemSignatureDialog(null); 
    }

    return (<div>
        <div className="k-card">
            <div className="k-card-body" >
                {!errorContent && htmlContent && formFields?.length > 0 && <CustomIframe htmlContent={htmlContent} expand={!!formResponseId} handleOnInit={handleOnInit} readonlyForm={readonlyForm} />}
                {errorContent && (<div className="error-content">{htmlContent}</div>)}
            </div>
            {!errorContent &&
                <ExportForm
                preview={false}
                isFinalized={isFinalized}
                imagesNotFound={imagesNotFound}
                formResponseId={formResponseId}
                setFormResponseId={setFormResponseId}
                formFields={formFields}
                shortLink={shortLink}
                formChanged={formChanged}
                setFormChanged={setFormChanged}
                language={language}
                hideQrCode={hideQrCode}
                initDataLoaded={initDataLoaded}
                awaitableDropdowns={awaitableDropdowns}
                date={date}
                readonlyForm={readonlyForm}
                hideSave={hideSave}
                hideDownload={hideDownload}
                hidePrint={hidePrint}
                />}

        </div>

        {speechRecognitionElement && <SpeechRecognitionHandler element={speechRecognitionElement} language={language} />}

        {itemSignatureDialog && <SignatureDialog
            onSubmit={onSubmitSignature}
            language={language}
            onClose={() => setItemSignatureDialog(null)} />}
    </div>
    );
};


const SignatureDialog = (props) => {
    const [value, setValue] = React.useState();
    const handleOnChange = (event) => {
        setValue(event.value);
    }

    const getHtmlText = (language) => {
        let text = {
            cancelButton: '',
            saveButton: '',
            title: ''
        };

        switch (language) {
            case "EN":
                text.cancelButton = 'Cancel';
                text.saveButton = 'Save';
                text.title = 'Digital Signature';
                break;
            case 'FR':
                text.cancelButton = 'Annuler';
                text.saveButton = 'Enregistrer';
                text.title = 'Signature num' + String.fromCharCode(233) + 'rique';
                break;
            default:
                text.cancelButton = 'Anuleaza';
                text.saveButton = 'Salveaza';
                text.title = 'Semnatura Digitala';
        }
        return text;
    }

    const htmlText = getHtmlText(props.language);

    return (
        <Dialog title={htmlText.title} onClose={props.onClose} className="">
            <div className="k-form k-mb-3 k-pl-3 k-pr-3">
                <div className="row k-mb-3 ">
                    <div className="k-form-fieldset col-12 k-mt-0 signature-wrapper ">
                        <Signature
                            value={value}
                            onChange={handleOnChange}
                            smooth={true}
                            maximizable={false}
                            fillMode={"flat"}
                            strokeWidth={6}
                        />
                    </div>
                </div>
                <div className="k-form-buttons k-text-center k-display-block k-mt-3">
                    <button type={"button"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-m-2"
                        onClick={props.onClose}>
                        {htmlText.cancelButton}
                    </button>
                    <button type={"button"} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-m-2"
                        onClick={() => props.onSubmit(value)} >
                        {htmlText.saveButton}
                    </button>
                </div>
            </div>
        </Dialog>
    );
};
