import { utils } from '../_helpers/utils'

export const externalService = {
    initForm,
    initTestForm,
    reInitFromSqlData,
    formFields,
    getDataSource,
    getInitialDataSources,
    saveOrUpdateForm,
    downloadPreviewFile,
    getBase64File
};

function initForm(params) {
    return utils.request({
        methodName: '/External/InitForm',
        method: 'POST',
        body: params
    });
}

function initTestForm(params) {
    return utils.request({
        methodName: '/External/InitTestForm',
        method: 'POST',
        body: params
    });
}

function reInitFromSqlData(params) {
    return utils.request({
        methodName: '/External/ReInitFromSqlData',
        method: 'POST',
        body: params
    });
}

function getDataSource(params) {
    return utils.request({
        methodName: '/External/GetDataSource',
        method: 'GET',
        queryString: params
    });
}

function getInitialDataSources(params) {
    return utils.request({
        methodName: '/External/GetInitialDataSources',
        method: 'POST',
        body: params,
        noLoading: true
    });
}

function formFields(params) {
    return utils.request({
        methodName: '/External/FormFields',
        method: 'POST',
        body: params
    });
}

function saveOrUpdateForm(params) {
    return utils.request({
        methodName: '/External/SaveOrUpdateForm',
        method: 'POST',
        body: params
    });
}

function downloadPreviewFile(params) {
    return utils.request({
        methodName: '/External/DownloadPreviewFile',
        method: 'POST',
        body: params,
        blob: true
    });
}

function getBase64File(language, shortLink) {
    return utils.request({
        methodName: `/external/form/base64file/${language}/${shortLink}`,
        method: 'GET'
    });
}