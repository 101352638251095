import $ from "expose-loader?exposes=$,jQuery!jquery";
import React, { useEffect } from "react";
import { getIframeHead } from "../../_helpers/iFrame";
import { utils } from '../../_helpers/utils';

const iframeId = "formHtmlContent";

export const CustomIframe = ({ htmlContent, expand, handleOnInit, readonlyForm }) => {

    useEffect(() => {
        utils.loading.setOnly(true);
    }, []);

    const handleIframeLoad = () => {
        var iframeHead = getIframeHead();
        $(iframeHead).append(`<link href="${window.location.origin}/style/bootstrap.min.css" rel="stylesheet" />`);
        $(iframeHead).append(`<link href="${window.location.origin}/style/fontsStyle.css" rel="stylesheet" />`);
        $(iframeHead).append(`<link href="${window.location.origin}/style/kendo.medis.fhir.css" rel="stylesheet" />`);
        $(iframeHead).append(`<link href="${window.location.origin}/style/medis.fhir.css" rel="stylesheet" />`);

        $(iframeHead).append(`<link rel="preconnect" href="https://fonts.googleapis.com">`);
        $(iframeHead).append(`<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>`);
        $(iframeHead).append(`<link href="https://fonts.googleapis.com/css2?family=Send+Flowers&display=swap" rel="stylesheet">`);


        if (handleOnInit) {
            handleOnInit();
        }
        utils.loading.setOnly(false);
    };

    const style = {
        width: '100vw',
        border: 'none',
        height: readonlyForm ? "94vh" : (expand ? '78vh' : '82vh'),
        margin: 0,
        padding: 0
    };

    return (
        <iframe
            id={iframeId}
            title=""
            onLoad={handleIframeLoad}
            srcDoc={htmlContent}
            style={style}
        />
    );
};


export const CustomSrcIframe = ({ src }) => {
    useEffect(() => {
        utils.loading.setOnly(true);
    }, []);

    const handleIframeLoad = () => {
        utils.loading.setOnly(false);
    };

    const style = {
        width: '100vw',
        border: 'none',
        height: "94vh",
        margin: 0,
        padding: 0
    };

    return (
        <iframe
            title=""
            onLoad={handleIframeLoad}
            src={src}
            style={style}
        />
    );
};