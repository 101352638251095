import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { externalService } from '../../_services/external.service';
import { utils } from '../../_helpers/utils';
import { errorMessages } from "../../_helpers/errorMessages.js";
import { CustomSrcIframe } from './customIframe';
import { ExternalForm } from './externalForm';

export const PreviewForm = (props) => {
    const params = useParams();
    const shortLink = params.shortLink;
    const [errorContent, setErrorContent] = React.useState("");
    const [page, setPage] = React.useState(0);
    const languageRef = useRef("RO");

   // #region init
    useEffect(() => {
        if (!shortLink) {
            utils.alert.set({
                title: errorMessages.errorName[languageRef.current],
                text: errorMessages.invalidUrl[languageRef.current]
            });

            // eroare 
            setErrorContent(errorMessages.generalError[languageRef.current]);
            return;
        }

        externalService.initForm(shortLink)
            .then(response => {
                if (!response) {
                    // eroare 
                    setErrorContent(errorMessages.generalError[languageRef.current]);
                    return;
                }

                languageRef.current = response.language;

                // daca a fost completat sau link-ul nu mai este valabil - afisam pdf-ul semnat
                if (response.formResponseId && (response.hasExpired || response.isFinalized)) {
                    setPage(2);
                }
                else {
                    setPage(1);
                }
            });
    }, []);

    if (errorContent) {
        return (<PreviewError errorContent={errorContent} />);
    }

    if (page == 1) {
        return (<ExternalForm readonlyForm={true} />);
    }

    if (page == 2) {
        return (<PreviewPdf shortLink={shortLink} language={languageRef.current} />);
    }

    return null;
};

const PreviewError = ({ errorContent }) => {
    return (<div>
        <div className="k-card">
            <div className="k-card-body" >
                <div className="error-content">
                    {errorContent}
                </div>
            </div>
        </div>
    </div>
    );
}

const PreviewPdf = ({ shortLink, language }) => {

    useEffect(() => {
        window.externalPrintForm = onExternalPrintForm;
    }, []);

    const onExternalPrintForm = () => {
        // functie apelata din power builder - va ramane asa nemodificata!!
        return externalService.getBase64File(language, shortLink)
            .then(response => {
                if (response?.content) {
                    const dataUri = 'externalPrintForm:data:application/pdf;base64,' + response.content;
                    utils.postMessage(dataUri);
                    return dataUri;
                }
            });
    }

    let config = window.MedisFhirConfig?.apiUrl;

    if (!config) {
        return null;
    }

    var src = config + `/external/form/downloadfile/${language}/${shortLink}?previewForm=true`;

    return (<div>
        <div className="k-card">
            <div className="k-card-body" >
                <CustomSrcIframe src={src} />
            </div>

        </div>
    </div>);
}