import { BehaviorSubject } from 'rxjs';

Date.prototype.toString = function dateToString() {
    let date = this;
    if (!date || !(date instanceof Date)) return "";

    let year = date.getFullYear();
    let month = ('0' + (date.getMonth() + 1)).slice(-2);
    let day = ('0' + date.getDate()).slice(-2);

    let hours = ('0' + date.getHours()).slice(-2);
    let minutes = ('0' + date.getMinutes()).slice(-2);
    let seconds = ('0' + date.getSeconds()).slice(-2);

    return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
};

Date.prototype.toDateString = function dateToString(template) {
    let date = this;
    if (!date || !(date instanceof Date)) return "";

    let year = date.getFullYear();
    let month = ('0' + (date.getMonth() + 1)).slice(-2);
    let day = ('0' + date.getDate()).slice(-2);

    if (template === 'yyyy/MM') return `${year}/${month}`;
    else if (template === 'yyyy') return `${year}`;
    return `${year}/${month}/${day}`;
};


const config = window.MedisFhirConfig;
if (!config) { console.error("config file is not defined."); }

const loadingSubject = new BehaviorSubject(false);
const loading = {
    subject: loadingSubject.asObservable(),
    count: 0,
    numberOfRequests: 0,
    setOnly: function (loading) {
        loadingSubject.next(loading);
    },
    set: function (loading) {
        if (loading) {
            this.numberOfRequests++;
            loadingSubject.next(loading);
        }
        else {
            this.count++;
            if (this.count < this.numberOfRequests) {
                return;
            }

            // reset requests           
            this.count = 0;
            this.numberOfRequests = 0;

            loadingSubject.next(loading);
        }        
    }
}

const alertSubject = new BehaviorSubject();
const alert = {
    subject: alertSubject.asObservable(),
    set: function (alert) {
        alertSubject.next(alert);
    }
}


const loadingCell = (tdElement, props) => {
    const field = props.field || '';
    let _value = getPath(props.dataItem, field);

    if (_value === undefined) {
        // daca exista obiectul - afisam -
        if (props.dataItem && Object.keys(props.dataItem).filter((x) => x !== 'Index').length > 0) {
            return <td>
                <span>-</span>
            </td>;
        }

        // shows loading cell if no data
        return <td>
            <span className="k-skeleton k-skeleton-text k-skeleton-pulse" style={{ width: '100%' }}></span>
        </td>;
    } // default rendering for this cell

    if (typeof _value === 'boolean') {
        return <td>
            <span>{_value ? 'Yes' : 'No'}</span>
        </td>;
    }

    return tdElement;
};


export const utils = {
    request,
    loading,
    alert,
    getPath,
    loadingCell,
    postMessage
};

function getRequestOptions(options) {
    if (options.method === 'POSTFORM') {
        let headers = requestHeaders();
        delete headers['Content-Type'];

        return {
            method: 'POST',
            headers: headers,
            body: options.body ? options.body : null
        };
    }

    return {
        method: options.method,
        headers: requestHeaders(),
        body: options.body ? JSON.stringify(options.body) : null
    };
}

function request(options) {
    if (!options.noLoading) {
        utils.loading.set(true);
    }

    if (options.queryString) {

        var queryParams = Object.getOwnPropertyNames(options.queryString);
        options.methodName += '?';

        queryParams.forEach(param => options.methodName += param + '=' + (options.queryString[param] != undefined ? encodeURIComponent(options.queryString[param]) : '') + '&');
        if (options.methodName.slice(-1) === '&') {
            options.methodName = options.methodName.slice(0, -1);
        }

    }

    const requestOptions = getRequestOptions(options);

    return fetch(`${config.apiUrl}${options.methodName}`, requestOptions)
        .then(handleResponse)
        .then(
            response => {
                if (!options.noLoading) {
                    utils.loading.set(false);
                }
                return response;
            },
            error => {
                if (!options.noLoading) {
                    utils.loading.set(false);
                }

                utils.alert.set({
                    title: 'Error',
                    text: error
                });
            });
}


function requestHeaders() {
    return { 'Content-Type': 'application/json' };
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function getPath(obj, path = "") {
    return path.split(".").reduce((out, key) => out ? out[key] : undefined, obj)
}

function postMessage(message) {
    if (window?.chrome?.webview?.postMessage) {
        window.chrome.webview.postMessage(message);
    }
    else if (window?.webBrowser?.postMessage) {
        window.webBrowser.postMessage(message);
    }
    else {
        window.parent.postMessage(message);
    }

}